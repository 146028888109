import { PERMISSION } from '@/Configs/Constants';

export default [
  {
    path: '/admin/auto_replies',
    component: require('../../components/AutoReplies/Index').default,
    meta: { permissionRequired: PERMISSION.SETTINGS__AUTO_REPLIES__MANAGE },
    children: [
      {
        path: '/admin/auto_replies/create',
        component: require('../../components/AutoReplies/Edit').default,
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__AUTO_REPLIES__MANAGE },
      },
      {
        path: '/admin/auto_replies/:id',
        component: require('../../components/AutoReplies/Edit').default,
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__AUTO_REPLIES__MANAGE },
      },
      {
        path: '',
        component: require('../../components/AdminIndexBox').default,
        props: { boxName: 'auto_replies' },
        meta: { permissionRequired: PERMISSION.SETTINGS__AUTO_REPLIES__MANAGE },
      },
    ],
  },
];
