<script type="text/babel">
import PusherHelper from '../util/PusherHelper';

export default {
  data() {
    return {
      hasSessionLimit: false,
      isInSettings: false,
    };
  },

  mounted() {
    if (this.routePathIsWhitelisted(this.$route.path)) {
      this.isInSettings = true;
    }

    PusherHelper.bindPusherEvent(this.$root.agencyChannel, 'UserSessionActivity', (data) => {
      if (data.user_id == this.$root.user.id) {
        this.hasSessionLimit = true;
      }
    });
  },

  methods: {
    useHere() {
      $('#LoginSessionLimitModal').modal('hide');
      $.post('/auth/use-current-session', { socket_id: PusherInstance.connection.socket_id });
      this.hasSessionLimit = false;
    },

    logout() {
      window.location.href = '/auth/logout';
    },

    checkSessionLimit() {
      if (this.hasSessionLimit && !this.isInSettings) {
        $('#LoginSessionLimitModal').modal({
          backdrop: 'static',
          keyboard: false,
        });
      }
    },

    routePathIsWhitelisted(path) {
      return path.substr(0, 6) === '/admin' || path.substr(0, 5) === '/chat' || path.startsWith('/workflows');
    },
  },

  watch: {
    $route(to, from) {
      if (!this.routePathIsWhitelisted(to.path)) {
        this.isInSettings = false;
        if (this.hasSessionLimit) {
          this.useHere();
        }
      } else {
        this.isInSettings = true;
      }
    },
    isInSettings(val) {
      this.checkSessionLimit();
    },
    hasSessionLimit(val) {
      this.checkSessionLimit();
    },
  },
};
</script>

<template>
  <div class="modal" id="LoginSessionLimitModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ $t('auth.login_session_limit_modal_title') }}</h4>
        </div>
        <div class="modal-body" v-html="$t('auth.login_session_limit_modal_body', { email: $root.user.email })"></div>
        <div class="modal-footer">
          <a class="btn dark-white" @click="logout">{{ $t('auth.login_session_limit_modal_logout') }}</a>
          <button type="button" class="btn info" @click="useHere">
            {{ $t('auth.login_session_limit_modal_use_here') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
