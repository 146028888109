import { PERMISSION } from '@/Configs/Constants';

export default [
  {
    path: '/help_center',
    component: require('../../components/HelpCenter/Index').default,
    meta: { permissionRequired: PERMISSION.HELP_CENTER__GENERAL__MANAGE },
    children: [
      {
        path: '/help_center/:id',
        component: require('../../components/HelpCenter/Edit').default,
        meta: { permissionRequired: PERMISSION.HELP_CENTER__GENERAL__MANAGE },
        children: [
          {
            path: '/help_center/:id/categories',
            component: require('../../components/HelpCenter/Categories').default,
            meta: { permissionRequired: PERMISSION.HELP_CENTER__GENERAL__MANAGE },
          },
          {
            path: '/help_center/:id/categories/:category_id',
            component: require('../../components/HelpCenter/Category').default,
            meta: { permissionRequired: PERMISSION.HELP_CENTER__GENERAL__MANAGE },
          },
          {
            path: '/help_center/:id/articles',
            component: require('../../components/HelpCenter/Articles').default,
            meta: { permissionRequired: PERMISSION.HELP_CENTER__GENERAL__MANAGE },
          },
          {
            path: '/help_center/:id/articles/create',
            component: require('../../components/HelpCenter/Article').default,
            meta: { permissionRequired: PERMISSION.HELP_CENTER__GENERAL__MANAGE },
          },
          {
            path: '/help_center/:id/articles/:article_id',
            component: require('../../components/HelpCenter/Article').default,
            meta: { permissionRequired: PERMISSION.HELP_CENTER__GENERAL__MANAGE },
          },
          {
            path: '/help_center/:id/blocks',
            component: require('../../components/HelpCenter/Blocks').default,
            meta: { permissionRequired: PERMISSION.HELP_CENTER__GENERAL__MANAGE },
          },
          {
            path: '/help_center/:id/blocks/:blockId',
            component: require('../../components/HelpCenter/Block').default,
            meta: { permissionRequired: PERMISSION.HELP_CENTER__GENERAL__MANAGE },
          },
          {
            path: '/help_center/:id/settings',
            component: require('../../components/HelpCenter/Settings').default,
            meta: { permissionRequired: PERMISSION.HELP_CENTER__GENERAL__MANAGE },
          },
          {
            path: '/help_center/:id/authentication',
            component: require('../../components/HelpCenter/Authentication').default,
            meta: { permissionRequired: PERMISSION.HELP_CENTER__GENERAL__MANAGE },
          },
          {
            path: '/help_center/:id/theme',
            component: require('../../components/HelpCenter/Theme').default,
            meta: { permissionRequired: PERMISSION.HELP_CENTER__GENERAL__MANAGE },
          },
        ],
      },
      {
        path: '',
        component: require('../../components/AdminIndexBox').default,
        props: { boxName: 'help_center' },
        meta: { permissionRequired: PERMISSION.HELP_CENTER__GENERAL__MANAGE },
      },
    ],
  },
];
