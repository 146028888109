import type { ServiceNotification } from './types';

export const POLLING_INTERVAL = 30000; // 5 minutes
export const IS_DISMISSED_KEY = 'pricing_migration_service_notification_dismissed';

export const MIGRATION_CUSTOM_NOTIFICATION: ServiceNotification = {
  id: 'pricing_migration_custom_notification',
  status: 'info',
  message:
    'You will soon be migrated to a new plan. An email containing further information will be sent to the admin.',
  link_url: 'https://www.youtube.com/watch?v=sbXvEjPsDqs',
  link_text: 'Watch video',
  is_dismissible: true,
};
