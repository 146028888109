// @ts-nocheck
// @ts-ignore
import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/admin/integrations/plugins',
    component: () =>
      import(
        /* webpackChunkName; "IntegrationPlugins" */ '@/components/IntegrationHub/Pages/CustomIntegrations/Index.vue'
      ),
    meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
    name: 'plugins',
  },
  {
    path: '/admin/integrations/plugins/create',
    component: () =>
      import(/* webpackChunkName: "PluginsCreate" */ '@/components/IntegrationHub/Pages/CustomIntegrations/Edit.vue'),
    props: { action: 'create' },
    meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
    name: 'createPlugins',
  },
  {
    path: '/admin/integrations/plugins/create/:type',
    component: () =>
      import(
        /* webpackChunkName: "PluginsCreateType" */ '@/components/IntegrationHub/Pages/CustomIntegrations/Edit.vue'
      ),
    props: { action: 'create' },
    meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
    name: 'createPluginsType',
  },
  {
    path: '/admin/integrations/plugins/:id',
    component: () =>
      import(/* webpackChunkName: "PluginsItem" */ '@/components/IntegrationHub/Pages/CustomIntegrations/Edit.vue'),
    props: { action: 'edit' },
    meta: { permissionRequired: PERMISSION.SETTINGS__APPS_INTEGRATIONS__MANAGE },
    name: 'editPluginsItem',
  },
];

export default routes;
