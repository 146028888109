import { defineStore } from 'pinia';
import { UnleashClient } from 'unleash-proxy-client';
import { ref } from 'vue';

import { isTestEnv } from '@/util/jest';

import type { FeatureFlag } from '@/Configs/Constants';
import type { IConfig, IToggle } from 'unleash-proxy-client';

type MaybeUnleashClient = UnleashClient | null;
let unleashClient: MaybeUnleashClient = null;

export const useFeatureFlagStore = defineStore('featureFlag', () => {
  const isInitialized = ref(false);
  const features = ref<IToggle[]>([]);

  const init = async ({
    userId,
    agencyId,
    betaEnrollment,
  }: {
    userId: string;
    agencyId: string;
    betaEnrollment: boolean;
  }) => {
    if (isInitialized.value || unleashClient) return;
    const config: IConfig = {
      url: `${window.UNLEASH_URL + window.UNLEASH_ENVIRONMENT}/proxy`,
      clientKey: window.UNLEASH_FRONTEND_CLIENT_KEY,
      appName: window.UNLEASH_INSTANCE_ID,
      refreshInterval: 5 * 60,
      environment: window.UNLEASH_ENVIRONMENT,
      metricsInterval: 5 * 60,
    };
    unleashClient = new UnleashClient(config);

    const context = {
      userId: userId,
      agencyId,
      'beta-group-all': betaEnrollment ? '1' : '0',
    };
    await unleashClient.updateContext(context);

    await new Promise<void>((resolve) => {
      if (!unleashClient) {
        console.error('unleashClient not initialized.');
        return;
      }

      const callback = () => {
        features.value = (unleashClient as UnleashClient).getAllToggles();
      };

      unleashClient.on('ready', () => {
        callback();
        resolve();
      });
      unleashClient.on('update', callback);
      unleashClient.start();
    });

    isInitialized.value = true;
  };

  const isEnabled = (name: FeatureFlag): boolean => {
    if (!unleashClient || !isInitialized.value) {
      if (!isTestEnv()) {
        console.error('useFeatureFlagStore().isEnabled called before store initialization.');
      }
      return false;
    }
    const feat = features.value.find((feature) => feature.name === name);
    return feat?.enabled ?? false;
  };

  return {
    isInitialized,
    features,
    init,
    isEnabled,
  };
});
