import { ENDPOINT } from '@/api/constants';
import { request } from '@/util';

import type { AxiosPromise } from 'axios';

export type CrmContact = {
  name: string;
  contact_url: string;
  contact_owner: string;
  company?: {
    id: number | string;
    name: string;
    number_of_employees: string;
  };
  phone_numbers: Array<{
    number: string;
    country_code: string;
  }>;
  email_addresses: Array<{
    email: string;
  }>;
};

type CrmContactResponse = Array<CrmContact>;

export type CrmOpportunity = {
  title: string;
  opportunity_url: string;
  monetary_amount: number;
  currency: string;
  close_date: string;
  type: any;
  pipeline: {
    name: string;
  };
  status: string;
};

type CrmOpportunitiesResponse = CrmOpportunity[];

export type CrmActivity = {
  title: string;
  type: string;
  start_date: string;
  description: string;
};

type CrmActivitiesResponse = CrmActivity[];

export type CrmLead = {
  lead_id: string;
  lead_custom_id: string;
  lead_name: string;
  lead_url: string;
  owner_id: string;
  owner_name: string;
  company: string;
  status: string;
  created_at: string;
};

type CrmLeadsResponse = CrmLead[];

export function fetchCrmContacts(installation_id: number, email: string): AxiosPromise<CrmContactResponse> {
  return request(`${ENDPOINT.INTEGRATIONS}/installations/${installation_id}/crm/contacts?email=${email}`, 'GET');
}

export function fetchCrmOpportunities(
  installation_id: number,
  company_id: number | string
): AxiosPromise<CrmOpportunitiesResponse> {
  return request(
    `${ENDPOINT.INTEGRATIONS}/installations/${installation_id}/crm/opportunities?company_id=${company_id}`,
    'GET'
  );
}

export function fetchCrmActivities(installation_id: number): AxiosPromise<CrmActivitiesResponse> {
  return request(`${ENDPOINT.INTEGRATIONS}/installations/${installation_id}/crm/activities`, 'GET');
}

export function fetchCrmLeads(installationId: number, email: string, phone: string) {
  const queryParams = `email=${email}${phone ? `&phone=${phone}` : ''}`;
  const url = `${ENDPOINT.INTEGRATIONS}/installations/${installationId}/crm/leads?${queryParams}`;

  return request<CrmLeadsResponse>(url, 'GET');
}

export function renameInstallation(
  integration_id: number,
  installation_id: number,
  name: string
): AxiosPromise<CrmActivitiesResponse> {
  return request(
    `${ENDPOINT.INTEGRATIONS}/admin/integrations/${integration_id}/installations/${installation_id}/name`,
    'PATCH',
    { name }
  );
}
