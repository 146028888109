import { PERMISSION } from '@/Configs/Constants';

export default [
  {
    path: '/admin/ticket_results',
    component: require('../../components/TicketResults/Index').default,
    meta: { permissionRequired: PERMISSION.SETTINGS__TICKET_RESULTS__MANAGE },
    children: [
      {
        path: '/admin/ticket_results/create',
        component: require('../../components/TicketResults/Edit').default,
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__TICKET_RESULTS__MANAGE },
      },
      {
        path: '/admin/ticket_results/:id',
        component: require('../../components/TicketResults/Edit').default,
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__TICKET_RESULTS__MANAGE },
      },
      {
        path: '',
        component: require('../../components/AdminIndexBox').default,
        props: { boxName: 'ticket_results' },
        meta: { permissionRequired: PERMISSION.SETTINGS__TICKET_RESULTS__MANAGE },
      },
    ],
  },
];
