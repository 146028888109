import { PERMISSION } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';

export default [
  {
    path: '/admin/company_profile/locations',
    redirect: SETTINGS_URL.ADMIN__LOCATIONS,
  },
  {
    path: '/admin/company_profile/locations/*',
    redirect: SETTINGS_URL.ADMIN__LOCATIONS,
  },
  {
    path: SETTINGS_URL.ADMIN__LOCATIONS,
    component: require(/* webpackChunkName: "LocationsIndex" */ '../../components/Location/Index').default,
    meta: { permissionRequired: PERMISSION.SETTINGS__LOCATIONS__MANAGE },
    children: [
      {
        path: SETTINGS_URL.ADMIN__LOCATIONS__CREATE,
        component: require(/* webpackChunkName: "LocationsCreate" */ '../../components/Location/Edit').default,
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__LOCATIONS__MANAGE },
      },
      {
        path: `${SETTINGS_URL.ADMIN__LOCATIONS}/:id`,
        component: require(/* webpackChunkName: "LocationsEdit" */ '../../components/Location/Edit').default,
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__LOCATIONS__MANAGE },
      },
      {
        path: '',
        component: require(/* webpackChunkName: "AdminIndexBox" */ '../../components/AdminIndexBox').default,
        props: { boxName: 'locations' },
        meta: { permissionRequired: PERMISSION.SETTINGS__LOCATIONS__MANAGE },
      },
    ],
  },
];
