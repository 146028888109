import { differenceInDays } from 'date-fns';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { fetchCustomerSubscription } from '@/api';
import { PLAN_PRICE_ID, PRICING_MODEL, SUBSCRIPTION_STATUS } from '@/Configs/Constants';
import { dateHelpers } from '@/util';

import { BASE_INITIAL_DATA, SUBSCRIPTION_INCLUDED_ITEM_DETAILS, SUBSCRIPTION_TITLES } from './constants';
import { getAddonsForSeatBased, getAddonsForUsageBased, getUserQuantity } from './utils';

import type { SubscriptionStore } from './types';
import type { PricingModel } from '@/types';
import type {
  ChargebeeSubscription,
  SubscriptionAddonDetailType,
  SubscriptionItem,
  SubscriptionStatus,
} from '@/types/chargebee';

export const useSubscriptionStore = defineStore('subscription', () => {
  const store = ref<SubscriptionStore>(BASE_INITIAL_DATA);
  const isInitialized = ref(false);

  const fetchSubscription = async () => {
    try {
      const subscription = await fetchCustomerSubscription();
      return subscription?.data;
    } catch (error) {
      store.value.isServiceDown = true;
    } finally {
      store.value.isLoading = false;
    }
  };

  async function loadSubscription(subscription: ChargebeeSubscription) {
    if (!subscription) {
      store.value.isLoading = false;
      return;
    }
    store.value.isServiceDown = false;

    const isTrialPlanSubscription = subscription?.subscription_items.some(({ item_price_id }: SubscriptionItem) =>
      item_price_id.includes(PLAN_PRICE_ID.USAGE_BASED__TRIAL)
    );

    // set addons
    store.value.addons =
      store.value.pricingModel === PRICING_MODEL.SEAT_BASED
        ? getAddonsForSeatBased(subscription?.subscription_items)
        : getAddonsForUsageBased(subscription?.subscription_items);

    store.value.userQuantity = getUserQuantity(store.value.pricingModel, subscription?.subscription_items);

    store.value.subscriptionName = SUBSCRIPTION_TITLES[store.value.pricingModel][subscription.plan.tier];
    store.value.subscriptionFrequency = subscription.billing_frequency ?? '';
    if (subscription.next_billing_at) {
      store.value.subscriptionDueDate = dateHelpers.formatEpochDate(subscription.next_billing_at);
    }
    if (subscription.cancelled_at) {
      store.value.subscriptionCancellationDate = dateHelpers.formatEpochDate(subscription.cancelled_at);
    }

    // set trial status
    store.value.isInTrial = Boolean(isTrialPlanSubscription) && subscription?.status !== SUBSCRIPTION_STATUS.CANCELLED;

    // has plan
    store.value.hasPlan =
      subscription && !store.value.isInTrial && subscription?.status !== SUBSCRIPTION_STATUS.CANCELLED;

    // subscription atrributes
    store.value.tierId = subscription.plan.tier;
    store.value.subscriptionId = subscription.chargebee_subscription_id;
    store.value.subscriptionStatus = subscription.status as SubscriptionStatus;
    if (store.value.isInTrial && subscription?.trial_end) {
      const end = new Date(subscription?.trial_end * 1000);
      const now = Date.now();
      store.value.trialExpirationDate = dateHelpers.formatEpochDate(subscription?.trial_end);
      store.value.trialCountdown = differenceInDays(end, now);
    }
    if (subscription.quote) {
      store.value.quote = subscription.quote;
    }
    store.value.isCancelled = subscription?.status === SUBSCRIPTION_STATUS.CANCELLED;
    store.value.isScheduledToCancel =
      subscription.status !== 'active' && Boolean(store.value.subscriptionCancellationDate);

    if (store.value.pricingModel === 'usage') {
      store.value.includedItems = SUBSCRIPTION_INCLUDED_ITEM_DETAILS.map((item) => {
        const quantityByTier = item.quantity?.[subscription.plan.tier] ?? 0;
        if (store.value.subscriptionFrequency === 'annually' && item.multiplyForYearly) {
          const yearlyQuantity = quantityByTier * 12;
          return { ...item, title: item.title.replace('{{quantity}}', yearlyQuantity.toString()) };
        }

        return { ...item, title: item.title.replace('{{quantity}}', quantityByTier) };
      });
      store.value.currentTermStart =
        (store.value.isInTrial ? subscription?.trial_start : subscription.current_term_start) ?? 0;

      store.value.currentTermEnd =
        (store.value.isInTrial ? subscription?.trial_end : subscription.current_term_end) ?? 0;
    }
    store.value.isLoading = false;
  }

  const init = async () => {
    if (isInitialized.value) {
      return;
    }
    await requestSubscription();
    isInitialized.value = true;
  };

  const requestSubscription = async () => {
    store.value.isLoading = true;
    const data = await fetchSubscription();
    store.value.pricingModel = data?.pricing_model ?? store.value.pricingModel;
    if (data) {
      loadSubscription(data);
    }
  };

  const setPricingModel = async (pricingModel: PricingModel) => (store.value.pricingModel = pricingModel);

  const getUsageBasedAddonQuantity = (type: SubscriptionAddonDetailType) => {
    if (store.value.pricingModel === PRICING_MODEL.SEAT_BASED) {
      return 0;
    }
    return store.value.addons.find((addon) => addon.type === type)?.quantity ?? 0;
  };

  return {
    subscription: store,
    getUsageBasedAddonQuantity,
    init,
    reload: requestSubscription,
    setPricingModel,
    isInitialized,
  };
});
