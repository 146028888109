import { MIDDLEWARE_ERROR } from '@/Configs/Constants/middlewareError';
import { useAgencyStore, useFeatureFlagStore, useUserStore } from '@/store/pinia';

import type { NavigationGuardWithThis } from 'vue-router';

export const loadFeatureFlags: NavigationGuardWithThis<undefined> = async (to) => {
  if (to.meta?.allowUnauthenticated) {
    return true;
  }
  const userStore = useUserStore();
  const agencyStore = useAgencyStore();
  const featureFlagStore = useFeatureFlagStore();

  await Promise.all([userStore.init(), agencyStore.init()]);
  if (!userStore?.user || !agencyStore?.agency) {
    throw new Error(MIDDLEWARE_ERROR.AUTHENTICATION_REQUIRED);
  }

  const context = {
    userId: userStore.user.id?.toString(),
    agencyId: userStore.user.agency_id?.toString(),
    betaEnrollment: agencyStore.agency.beta_enrollment,
  };
  await featureFlagStore.init(context);
};
