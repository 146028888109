import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { fetchOneTimePurchaseInvoices } from '@/api';
import { flashError } from '@/util/errorHelper';

import type { OneTimePurchaseInvoice } from '@/types/billing';

export const useBillingStore = defineStore('billing', () => {
  const isLoading = ref(true);
  const invoices = ref<OneTimePurchaseInvoice[]>([]);

  async function init() {
    if (invoices.value.length > 0) return;
    await getInvoices();
  }

  async function getInvoices() {
    isLoading.value = true;
    try {
      const { data } = await fetchOneTimePurchaseInvoices();
      invoices.value = data.items;
    } catch (err: any) {
      flashError(err?.message);
      console.error(err);
    } finally {
      isLoading.value = false;
    }
  }

  const hasInvoice = computed(() => invoices.value.length > 0);
  const hasCarrierCostInvoice = computed(() => invoices.value.filter((item) => item.carrier_cost_blocks).length > 0);
  const hasConversationInvoice = computed(
    () => invoices.value.filter((item) => item.standard_conversation_blocks).length > 0
  );

  return {
    getInvoices,
    init,
    invoices,
    hasInvoice,
    hasCarrierCostInvoice,
    hasConversationInvoice,
    isLoading,
  };
});
