import { PERMISSION } from '@/Configs/Constants';

export default [
  {
    path: '/admin/ivr',
    component: require('../../components/Ivr/Index').default,
    meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
    children: [
      {
        path: '/admin/ivr/create',
        component: require('../../components/Ivr/Edit').default,
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
      },
      {
        path: '/admin/ivr/:id',
        component: require('../../components/Ivr/Edit').default,
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__CHANNELS__MANAGE },
      },
    ],
  },
];
