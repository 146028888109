import { ref } from 'vue';

export const useInboxTicketDrag = () => {
  const isDraggingTicketSidebar = ref(false);
  const initialMouseX = ref(0);
  const initialSidebarWidth = ref(0);
  const ticketSidebarWidth = ref(300);

  const startDrag = (event: MouseEvent) => {
    event.preventDefault();
    isDraggingTicketSidebar.value = true;
    initialMouseX.value = event.clientX;
    initialSidebarWidth.value = ticketSidebarWidth.value;
    document.addEventListener('mousemove', onDrag);
    document.addEventListener('mouseup', stopDrag);
  };

  const stopDrag = () => {
    isDraggingTicketSidebar.value = false;
    document.removeEventListener('mousemove', onDrag);
    document.removeEventListener('mouseup', stopDrag);
  };

  const onDrag = (event: MouseEvent) => {
    if (isDraggingTicketSidebar) {
      const deltaX = event.clientX - initialMouseX.value;
      const newWidth = initialSidebarWidth.value + deltaX;
      const minWidth = 300;
      const maxWidth = 600;
      if (newWidth >= minWidth && newWidth <= maxWidth) {
        ticketSidebarWidth.value = newWidth;
        sessionStorage.ticketSidebarWidth = newWidth;
      }
    }
  };

  return {
    startDrag,
    stopDrag,
    onDrag,
    ticketSidebarWidth,
    isDraggingTicketSidebar,
  };
};
