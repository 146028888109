import { UserPlusLinear, CommentsLinear, ServerLinear, TaskAutomationFill } from '@trengo/trengo-icons';

import { PRICING_MODEL, SUBSCRIPTION_STATUS } from '@/Configs/Constants';

import type { SubscriptionStore } from './types';
import type { SubscriptionIncludedItemDetailUsageBased } from '@/types';

export const SUBSCRIPTION_TITLES = {
  seats: {
    tier1: 'Grow',
    tier2: 'Scale',
    tier3: 'Enterprise',
  },
  usage: {
    tier1: 'Essentials',
    tier2: 'Boost',
    tier3: 'Pro',
  },
} as const;

export const SUBSCRIPTION_INCLUDED_ITEM_DETAILS: SubscriptionIncludedItemDetailUsageBased[] = [
  {
    title: '{{quantity}} user seats',
    icon: UserPlusLinear,
    quantity: {
      tier1: 5,
      tier2: 5,
      tier3: 5,
    },
  },
  {
    title: '{{quantity}} Year data retention',
    icon: ServerLinear,
    quantity: {
      tier1: 1,
      tier2: 2,
      tier3: 3,
    },
  },
  {
    title: '{{quantity}} conversations',
    icon: CommentsLinear,
    multiplyForYearly: true,
    quantity: {
      tier1: 100,
      tier2: 300,
      tier3: 1000,
    },
  },
  // FIXME: Uncomment this when the feature is ready https://linear.app/trengo/issue/WALL-402/show-included-suggested-replies-on-subscription-overview-once-they-are
  // {
  //   title: '{{quantity}} Suggested Replies',
  //   icon: TaskAutomationFill,
  //   multiplyForYearly: true,
  //   quantity: {
  //     tier1: 20,
  //     tier2: 50,
  //     tier3: 100,
  //   },
  // },
];

export const BASE_INITIAL_DATA: SubscriptionStore = {
  addons: [],
  hasPlan: false,
  isCancelled: false,
  isInTrial: false,
  isLoading: true,
  isScheduledToCancel: false,
  isScheduledToDowngrade: false,
  isServiceDown: false,
  tierId: null,
  pricingModel: PRICING_MODEL.SEAT_BASED,
  quote: null,
  subscriptionCancellationDate: '',
  subscriptionDueDate: '',
  subscriptionFrequency: '',
  subscriptionId: '',
  subscriptionName: '',
  subscriptionStatus: SUBSCRIPTION_STATUS.ACTIVE,
  trialCountdown: 0,
  trialExpirationDate: '',
  userQuantity: 0,
};
