import { PERMISSION, SETTINGS_URL } from '@/Configs/Constants';

import type { RouteRecordRaw } from 'vue-router';

export const SETTINGS_INBOX_ROUTE_NAME = {
  INBOX: 'inbox',
};

const settingsRoutesInbox: RouteRecordRaw[] = [
  {
    name: SETTINGS_INBOX_ROUTE_NAME.INBOX,
    path: SETTINGS_URL.ADMIN__APPS_INBOX,
    component: () => {
      return import(/* webpackChunkName: "SettingsInbox" */ '@/components/Admin/SettingsInbox/SettingsInbox.vue');
    },
    meta: {
      permissionRequired: PERMISSION.SETTINGS__INBOX,
    },
  },
];
export default settingsRoutesInbox;
