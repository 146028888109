import {
  EyeLinear,
  UserPlusLinear,
  CommentsLinear,
  ServerLinear,
  WalletPlusLinear,
  PhoneLinear,
  MegaphoneLinear,
  ShareLinear,
} from '@trengo/trengo-icons';

import { SUBSCRIPTION_ADDON_CATEGORY, SUBSCRIPTION_ADDON_CHARGEBEE_TYPE } from '@/Configs/Constants';
import { formatPrice } from '@/util/currency';

import type {
  BillingFrequency,
  PlanId,
  SubscriptionAddonDetail,
  SubscriptionAddonUsageBased,
  SubscriptionAddonsByCategoryUsageBased,
} from '@/types';

const PLAN_NAME = {
  tier1: 'Essentials',
  tier2: 'Boost',
  tier3: 'Pro',
};

const BILLING_FREQUENCY = {
  monthly: 'Monthly',
  annually: 'Annually',
};

export const SUBSCRIPTION_ADDONS: SubscriptionAddonsByCategoryUsageBased[] = [
  {
    title: 'Additional conversations',
    category: SUBSCRIPTION_ADDON_CATEGORY.CONVERSATIONS,
    items: [],
  },
  {
    title: 'Additional seats',
    category: SUBSCRIPTION_ADDON_CATEGORY.SEATS,
    items: [],
  },
  {
    title: 'Carrier costs',
    category: SUBSCRIPTION_ADDON_CATEGORY.CARRIER_COSTS,
    items: [],
  },
  {
    title: 'Add-ons',
    category: SUBSCRIPTION_ADDON_CATEGORY.ADDONS,
    items: [],
  },
];

export const SUBSCRIPTION_ADDON_DETAILS: readonly SubscriptionAddonDetail[] = [
  {
    item_chargebee_type: SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.USER_SEATS,
    category: SUBSCRIPTION_ADDON_CATEGORY.SEATS,
    title: '{{quantity}} user seats',
    is_quantity_based: true,
    icon: UserPlusLinear,
  },
  {
    item_chargebee_type: SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.OBSERVER_SEATS,
    category: SUBSCRIPTION_ADDON_CATEGORY.SEATS,
    title: '{{quantity}} observer seats',
    is_quantity_based: true,
    icon: EyeLinear,
  },
  {
    item_chargebee_type: SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.CONVERSATION,
    category: SUBSCRIPTION_ADDON_CATEGORY.CONVERSATIONS,
    title: '{{quantity}}x100 conversations',
    is_quantity_based: true,
    icon: CommentsLinear,
  },
  {
    item_chargebee_type: SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.VOICE_CHANNEL,
    category: SUBSCRIPTION_ADDON_CATEGORY.ADDONS,
    title: 'Voice channel',
    is_quantity_based: false,
    icon: PhoneLinear,
  },
  {
    item_chargebee_type: SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.BROAD_CASTING,
    category: SUBSCRIPTION_ADDON_CATEGORY.ADDONS,
    title: 'Broadcasting',
    is_quantity_based: false,
    icon: MegaphoneLinear,
  },
  {
    item_chargebee_type: SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.FLOWBOT,
    category: SUBSCRIPTION_ADDON_CATEGORY.ADDONS,
    title: 'Flowbot',
    is_quantity_based: false,
    icon: ShareLinear,
  },
  {
    item_chargebee_type: SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.API_HIGH_RATE_LIMIT,
    category: SUBSCRIPTION_ADDON_CATEGORY.ADDONS,
    title: 'API High Rate Limit',
    is_quantity_based: false,
    icon: WalletPlusLinear,
  },
  {
    item_chargebee_type: SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.DATA_STORAGE,
    category: SUBSCRIPTION_ADDON_CATEGORY.ADDONS,
    title: '{{quantity}} Year additional data retention',
    is_quantity_based: true,
    icon: ServerLinear,
  },
  {
    item_chargebee_type: SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.CARRIER_COST_BALANCE,
    category: SUBSCRIPTION_ADDON_CATEGORY.CARRIER_COSTS,
    title: '{{carrierCosts}} carrier cost balance',
    is_quantity_based: true,
    icon: WalletPlusLinear,
  },
];

export const MULTIPLIER_EURO_25 = 2500;

export const getPlanName = (planId: PlanId) => PLAN_NAME[planId];

export const getBillingFrequencyName = (billingFrequency: BillingFrequency) => BILLING_FREQUENCY[billingFrequency];

export const mapAddonsToCategoryBased = (
  addons: SubscriptionAddonUsageBased[]
): SubscriptionAddonsByCategoryUsageBased[] => {
  if (addons.length === 0) {
    return [];
  }

  const mappedSubscriptionItems =
    addons
      .map((item) => {
        const subscriptionDetail = SUBSCRIPTION_ADDON_DETAILS.find(
          ({ item_chargebee_type }) => item_chargebee_type === item.type
        );
        if (!subscriptionDetail) return null;

        let title = '';

        switch (true) {
          case subscriptionDetail?.item_chargebee_type === SUBSCRIPTION_ADDON_CHARGEBEE_TYPE.CARRIER_COST_BALANCE:
            title =
              subscriptionDetail?.title.replace('{{carrierCosts}}', formatPrice(item.quantity * MULTIPLIER_EURO_25)) ??
              '';
            break;
          case subscriptionDetail?.is_quantity_based: {
            title = subscriptionDetail?.title.replace('{{quantity}}', item.quantity.toString()) ?? '';
            break;
          }
          default:
            title = subscriptionDetail?.title ?? '';
            break;
        }

        subscriptionDetail.title = title;

        return subscriptionDetail;
      })
      .filter((item) => item?.item_chargebee_type) || [];

  const res =
    SUBSCRIPTION_ADDONS.map(
      (addonCategory) =>
        ({
          ...addonCategory,
          items: mappedSubscriptionItems.filter((item) => item?.category === addonCategory.category) ?? [],
        } as SubscriptionAddonsByCategoryUsageBased)
    ).filter((item) => item.items.length > 0) ?? [];

  return res;
};

export const getCarrierCostOptions = (currentCarrierCosts?: number) => {
  const options = [];
  for (let i = 0; i <= 40; i++) {
    const isCurrent = currentCarrierCosts === i;
    options.push({
      text: formatPrice(i * MULTIPLIER_EURO_25) + (isCurrent ? ' (Current)' : ''),
      value: i,
    });
    if (i >= 20) i += 3; // multiply by 100
  }
  return options;
};
