import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { fetchUsageOverview } from '@/api';
import { CONVERSATION_TYPE, PERIOD_TYPE } from '@/Configs/Constants';

import type { PeriodBreakdown } from '../types';
import type { AgencyUsageResponse, ConversationBreakdownItem, PeriodBreakdownItem } from '@/api';

export const useUsageOverviewStore = defineStore('usage', () => {
  const isPending = ref(false);
  const hasError = ref(false);

  const breakdowns = ref<PeriodBreakdown[]>([]);
  const currentCycleIndex = ref(-1);
  const selectedBreakdownIndex = ref(-1);

  const getUsageOverview = async () => {
    isPending.value = true;
    breakdowns.value = [];
    try {
      const { data } = await fetchUsageOverview();
      prepareBreakdownList(data);
    } catch (err) {
      hasError.value = true;
      console.error(err);
    } finally {
      isPending.value = false;
    }
  };

  const mapBreakdown = (breakdown: PeriodBreakdownItem): PeriodBreakdown => ({
    periodType: breakdown.period_type,
    startDate: breakdown.start_date,
    endDate: breakdown.end_date,
    conversations: breakdown.conversations.map((conversation: ConversationBreakdownItem) => ({
      type: conversation.conversation_type,
      quota: conversation.commitment_in_bundles * 100,
      finished: conversation.finished_conversations_count,
      inProgress: conversation.in_progress_conversations_count,
    })),
    carrierCosts: {
      used: breakdown.carrierCosts.used * 100,
      quota: breakdown.carrierCosts.commitment_in_bundles * 2500,
    },
  });

  const prepareBreakdownList = (usageResponse: AgencyUsageResponse | null) => {
    if (!usageResponse) return;

    usageResponse.previous_periods_break_down?.reverse()?.forEach((breakdown: PeriodBreakdownItem) => {
      breakdowns.value.push(mapBreakdown(breakdown));
    });
    if (usageResponse.current_period_breakdown) {
      breakdowns.value.push(mapBreakdown(usageResponse.current_period_breakdown));
    }

    const hasUpcomingBreakdown =
      (usageResponse.upcoming_period_breakdown?.upcoming_standard_conversations_count ?? 0) > 0 ||
      (usageResponse.upcoming_period_breakdown?.upcoming_ai_conversations_count ?? 0) > 0;

    if (hasUpcomingBreakdown) {
      breakdowns.value.push({
        periodType: PERIOD_TYPE.UPCOMING,
        startDate: 0,
        endDate: 0,
        conversations: [
          {
            type: CONVERSATION_TYPE.STANDARD,
            inProgress: usageResponse.upcoming_period_breakdown?.upcoming_standard_conversations_count ?? 0,
            finished: 0,
          },
          {
            type: CONVERSATION_TYPE.INTELLIGENT,
            inProgress: usageResponse.upcoming_period_breakdown?.upcoming_ai_conversations_count ?? 0,
            finished: 0,
          },
        ],
      });
    }

    const currentIndex = breakdowns.value.findIndex(
      (item) => item.startDate === usageResponse?.current_period_breakdown?.start_date
    );
    currentCycleIndex.value = currentIndex;
    setSelectedBreakdownIndex(currentIndex);
  };

  const setSelectedBreakdownIndex = (index: number) => {
    selectedBreakdownIndex.value = index;
  };

  const hasUsage = computed(
    () => !isPending.value && !hasError.value && breakdowns.value.length > 0 && selectedBreakdown.value
  );

  const selectedBreakdown = computed(() => breakdowns.value[selectedBreakdownIndex.value]);
  const isCurrentCycle = computed(() => selectedBreakdownIndex.value === currentCycleIndex.value);
  const isUpcoming = computed(() => selectedBreakdown.value.periodType === PERIOD_TYPE.UPCOMING);
  const isTrial = computed(() => selectedBreakdown.value.periodType === PERIOD_TYPE.TRIAL);
  const isVoid = computed(() => selectedBreakdown.value.periodType === PERIOD_TYPE.VOID);
  const isYearly = computed(() => selectedBreakdown.value.periodType === PERIOD_TYPE.YEAR);

  return {
    isPending,
    hasUsage,
    breakdowns,
    currentCycleIndex,
    selectedBreakdownIndex,
    selectedBreakdown,
    isCurrentCycle,
    isUpcoming,
    isTrial,
    isVoid,
    isYearly,
    hasError,
    setSelectedBreakdownIndex,
    getUsageOverview,
  };
});
