// @ts-nocheck
// @ts-ignore

import { PERMISSION } from '@/Configs/Constants';

import type { RouteRecordRaw } from 'vue-router';

const ai: RouteRecordRaw[] = [
  {
    path: '/ai',
    component: () => {
      return import(/* webpackChunkName: "Reports" */ '@/components/AI/Pages/Index.vue');
    },
    meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
    name: 'aiBase',
    children: [
      {
        path: '/ai',
        component: require('@/components/AI/Pages/Dashboard.vue').default,
        meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
        name: 'aiDashboard',
      },
      {
        path: '/ai/terms',
        component: require('@/components/AI/Pages/Dashboard.vue').default,
        meta: { permissionRequired: PERMISSION.REPORTING__GENERAL__MANAGE },
        name: 'aiDashboardTerms',
      },
    ],
  },
];

export default ai;
