import { request } from '@/util/request';

import { ENDPOINT } from '../constants';

import type { ChannelsResponse } from '@/store/pinia/channels/types';

export function fetchChannels() {
  return request<ChannelsResponse>(ENDPOINT.ME_CHANNELS, 'GET');
}

export function fetchAllChannels() {
  return request<ChannelsResponse>(ENDPOINT.CHANNELS, 'GET');
}
