<template>
  <gray-card>
    <template #default>
      <div class="mb-8 flex flex-row items-center">
        <div class="flex h-44px w-44px items-center justify-center rounded-xl bg-galaxy-600 p-2 sm:h-48px sm:w-48px">
          <img class="w-24" :src="`${$root.assetsURL}img/users_white.svg`" />
        </div>
        <div class="ml-4 flex flex-col">
          <div class="t-text-mobile-body-md sm:t-text-button-lg sm:mb-1">
            {{ $t('getting_started.title_invite_a_colleague') }}
          </div>
          <div class="t-text-desktop-body-xs sm:t-text-desktop-body-sm text-grey-800">
            {{ $t('getting_started.subtitle_invite_a_colleague') }}
          </div>
        </div>
      </div>
      <add-user-modal
        v-if="openAddUser && $authorization().hasPermission(PERMISSION.PERMISSIONS__USERS__MANAGE)"
        v-model="openAddUser"
        @close="closeAddUserModal"
      />
      <div
        class="
          mb-4
          grid
          xs3:grid-cols-2 xs3:place-items-center xs3:gap-4 xs3:px-8
          xs:grid-cols-4 xs:place-items-start xs:gap-6 xs:px-0
          sm:mb-3
          lg:gap-0
        "
      >
        <add-user v-for="user in users" :key="user.id" :user="user" @click="handleOpenModal" />
      </div>
      <div>
        <span class="t-text-button-md cursor-pointer text-leaf-500 hover:underline" @click="handleOpenModal">
          {{ $t('getting_started.button_invite_a_colleague') }}
        </span>
      </div>
    </template>
  </gray-card>
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapStores } from 'pinia';

import { fetchAllUsers } from '@/api';
import AddUserModal from '@/components/Users/components/AddUsers/AddUserModal';
import { FEATURE_FLAG_ACTIVATION, PERMISSION } from '@/Configs/Constants';
import { useFeatureFlagStore } from '@/store/pinia';

import AddUser from './AddUser';
import GrayCard from '../GrayCard';

export default {
  name: 'AddUsers',
  components: { AddUserModal, AddUser, GrayCard },
  computed: {
    ...mapStores(useFeatureFlagStore),
  },
  data() {
    return {
      openAddUser: false,
      users: [{}, {}, {}, {}], // this initial will make our for-loop work
      PERMISSION,
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    handleOpenModal() {
      this.openAddUser = true;
    },
    /**
     * fill only the first X users
     * @param users
     * @returns {[{}, {}, {}, {}]}
     */
    mapUsers(users) {
      let localUsers = this.users;
      users.map((user, index) => {
        const mapUser = {
          id: user.id,
          abbr: user.abbr,
          first_name: user.first_name,
          last_name: user.last_name,
          profile_image: user.profile_image,
          color: user.color,
        };
        if (index < 4) {
          localUsers[index] = mapUser;
        }
      });
      return localUsers;
    },
    async getUsers() {
      const cacheEnabled = this.featureFlagStore.isEnabled(FEATURE_FLAG_ACTIVATION.TA_CACHE_REQUEST);
      return fetchAllUsers({ cacheEnabled }).then(() => {
        this.users = cloneDeep(this.mapUsers(this.$root.users));
      });
    },
    closeAddUserModal() {
      console.log('closeAddUserModal  called');
      this.openAddUser = false;
    },
  },
};
</script>
