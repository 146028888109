import { AnalyticsBrowser } from '@segment/analytics-next';

import { EVENT_NAME } from './eventName';
import { isReportsRouteWithFilter, isTicketRoute } from '../routeHelpers';

import type { IdentifyUserParams, PageTrackingParams, SegmentTrackSettingsPageParams, TrackEventParams } from './types';

export const segmentAnalytics = new AnalyticsBrowser();

export const initializeSegmentAnalytics = (): void => {
  segmentAnalytics.load({ writeKey: window.SEGMENT_WRITE_KEY });
};

export const IdentifySegmentUser = ({
  user,
  companyProfile,
  agency,
  isDesktopApp,
  mobileDevice,
  channels,
}: IdentifyUserParams): void => {
  // @ts-ignore
  segmentAnalytics.identify(user.id, {
    userEmail: user?.email,
    userFullname: user?.full_name,
    userRoleId: user?.role?.id,
    userRoleName: user?.role?.name,
    agencyId: companyProfile?.profile?.id,
    agencyCreatedAt: agency?.created_at,
    agencyIndustry: agency.data?.industry,
    agencyCountry: companyProfile?.profile?.country,
    agencyIsTrialing: agency?.is_trialing,
    isDesktopApp: isDesktopApp,
    isMobileApp: mobileDevice,
    isDesktopBrowser: !isDesktopApp && !mobileDevice,
    agencyChannelCount: channels.length,
    agencyHasChannelEmail: channels?.some((c) => c.type === 'EMAIL'),
    agencyHasChannelWaBusiness: channels?.some((c) => c.type === 'WA_BUSINESS'),
    agencyHasChannelFacebook: channels?.some((c) => c.type === 'FACEBOOK'),
    agencyHasChannelInstagram: channels?.some((c) => c.type === 'INSTAGRAM'),
    agencyHasChannelVoip: channels?.some((c) => c.type === 'VOIP'),
    agencyHasChannelSms: channels?.some((c) => c.type === 'SMS'),
    agencyHasChannelTelegram: channels?.some((c) => c.type === 'TELEGRAM'),
    agencyHasChannelCustom: channels?.some((c) => c.type === 'CUSTOM'),
    agencyHasChanneGbm: channels?.some((c) => c.type === 'GBM'),
  });
};

export const segmentTrackEvent = ({ eventName, agencyId, userId, page }: TrackEventParams): void => {
  segmentAnalytics.track(eventName, {
    agencyId,
    userId,
    page,
  });
};

export const trackPageWithSegmentAnalytics = ({ user, route }: PageTrackingParams): void => {
  if (!user?.id || isTicketRoute(route) || isReportsRouteWithFilter(route)) return;

  segmentAnalytics.page({
    name: 'Page',
  });
};

export const trackSettingsPageWithSegment = ({
  isSettingsPage,
  pageUrl,
  agencyId,
  userId,
  category,
  featureName,
}: SegmentTrackSettingsPageParams): void => {
  if (!isSettingsPage) return;
  segmentAnalytics.page(EVENT_NAME.SETTINGS_PAGE, {
    pageUrl,
    agencyId,
    userId,
    category,
    featureName,
  });
};
