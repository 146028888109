<template>
  <div v-if="showBalanceProgress && carrierCosts">
    <t-inline-banner v-if="isCurrentCycle && hasCarrierCostInvoice" class="mt-4">
      <template #icon>
        <info-linear />
      </template>
      {{ $t('usage_overview.overview_section_balance_quota_update_pending') }}
    </t-inline-banner>
    <usage-progress-card
      v-if="showUsageProgressCard"
      :spent-amount="carrierCosts.used"
      :quota="carrierCosts.quota ?? 0"
      format="currency"
      :type="'balance'"
      :show-button="isCurrentCycle"
    >
      <template #icon>
        <t-thumbnail size="large">
          <wallet-minus-linear size="m" />
        </t-thumbnail>
      </template>
      <template #title>{{ $t('usage_overview.overview_section_balance_spent_heading') }}</template>
      <template #button>
        <t-button :disabled="hasCarrierCostInvoice" size="sm" btn-style="secondary" @click="openCarrierCostsModal">
          {{ $t('usage_overview.add_carrier_costs_top_up_balance') }}
        </t-button>
      </template>
    </usage-progress-card>
    <div v-else class="flex items-center justify-between gap-3 py-4">
      <t-thumbnail size="large">
        <wallet-minus-linear />
      </t-thumbnail>
      <div class="flex flex-grow flex-col gap-1">
        <span class="t-text-md-emphasize leading-6 text-grey-800">
          {{ $t('usage_overview.overview_section_carrier_costs_commitment_needed_title') }}
        </span>
        <span class="t-text-sm leading-5 text-grey-600">
          {{ $t('usage_overview.overview_section_carrier_costs_commitment_needed_description') }}
        </span>
      </div>
      <t-button size="sm" btn-style="primary" @click="() => $router.push({ name: 'subscription' })">
        {{ $t('usage_overview.overview_section_edit_subscription') }}
      </t-button>
    </div>

    <separator />
  </div>
</template>

<script lang="ts" setup>
import { WalletMinusLinear } from '@trengo/trengo-icons';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { useQueryParameterOpenState } from '@/composables/useQueryParameterOpenState';
import { useBillingStore } from '@/store/pinia';

import Separator from './Separator.vue';
import UsageProgressCard from './UsageProgressCard.vue';
import { useUsageOverviewStore } from '../store';

const { isUpcoming, isVoid, isCurrentCycle, selectedBreakdown } = storeToRefs(useUsageOverviewStore());
const { hasCarrierCostInvoice } = storeToRefs(useBillingStore());
const { handleOpen: openCarrierCostsModal } = useQueryParameterOpenState('addCarrierCostsModal');

const carrierCosts = computed(() => selectedBreakdown.value?.carrierCosts);
const showBalanceProgress = computed(() => !isVoid.value && !isUpcoming.value);

const showUsageProgressCard = computed(
  () => (carrierCosts.value?.quota && carrierCosts.value.quota > 0) || !isCurrentCycle
);
</script>
