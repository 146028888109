import { PERMISSION } from '@/Configs/Constants';
import { app } from '@/main';

import type { Permission } from '@/types';

/**
 * @deprecated
 * use useUserStore().hasPermission instead
 */
const authorization = {
  install() {
    app.mixin({
      methods: {
        log(missingPermission: Permission) {
          if (window.missingPermissions && !window.missingPermissions.includes(missingPermission)) {
            window.missingPermissions?.push(missingPermission);
            console.log(
              `User with role %c${this.$root?.user?.role.name}%c is missing permission %c${missingPermission}%c`,
              'color: green',
              '',
              'color: red',
              ''
            );
          }
        },
        $authorization() {
          return {
            grantedPermissions: this.grantedPermissions,
            hasPermission: function (permission: Permission) {
              if (this.grantedPermissions.includes(permission)) {
                return true;
              }
              if (typeof window.DYNAMIC_BUILD_VERSION !== 'undefined' && window.DYNAMIC_BUILD_VERSION !== '') {
                this.log(permission);
              }
              return false;
            }.bind(this),
            hasAnyPermission: function (permissions: Permission[]) {
              return permissions?.some((permission: string) => this.hasPermission(permission));
            },
          };
        },
      },
      data() {
        return {
          ...PERMISSION,
        };
      },
      computed: {
        grantedPermissions() {
          return this.$store.getters['initialData/user'].permissions ?? [];
        },
      },
    });
  },
};

export default authorization;
