<script lang="ts">
import { mapGetters } from 'vuex';

import UserIndicator from '@/components/Chat/UserIndicator.vue';
import { FEATURE_FLAG_INBOX } from '@/Configs/Constants';
import { useFeatureFlagStore } from '@/store/pinia';
import PusherHelper from '@/util/PusherHelper';
import { cachedRequest } from '@/util/request';

import type { User } from '@/types';

export default {
  data: function () {
    return {
      allUsers: [] as User[],
    };
  },
  computed: {
    ...mapGetters({ threadByUserId: 'chat/threadByUserId' }),

    onlineUsers() {
      return this.allUsers.filter((user: User) => user.is_online && user.user_status === 'ONLINE');
    },

    offlineUsers() {
      return this.allUsers.filter((user: User) => !user.is_online || user.user_status === 'OFFLINE');
    },

    awayUsers() {
      return this.allUsers.filter((user: User) => user.is_online && user.user_status === 'AWAY');
    },

    chatEnabled() {
      // FIXME: remove unused code https://linear.app/trengo/issue/TIN-1455/remove-unused-team-chat-code
      return false;
    },
  },

  components: {
    UserIndicator,
  },

  methods: {
    fetchUsers() {
      cachedRequest({ method: 'get', url: '/client-api/users/list' }, true).then((res) => {
        const users = res.data.users;
        this.$root.users = users;
        this.$store.dispatch('usersInternalChat/setUsers', this.$root.users);

        this.allUsers = users;
      });
    },

    setUserStatus(id: User['id'], userStatus: User['user_status'], voipStatus: User['voip_status']) {
      const user = this.allUsers.find((user: User) => user.id === id);

      user.voip_status = voipStatus;
      user.user_status = userStatus;
    },
  },

  mounted() {
    this.fetchUsers();

    PusherHelper.bindPusherEvent(this.$root.agencyChannel, 'UserOnlineStatusChangedEvent', (data) => {
      if (data.user !== null) {
        this.setUserStatus(data.user.id, data.user.user_status, data.user.voip_status);
      }
    });
  },

  beforeUnmount() {
    PusherHelper.unbindPusherEvent(this.$root.agencyChannel, 'UserOnlineStatusChangedEvent');
  },
};
</script>

<template>
  <div class="b-l flex flex-col items-center">
    <div class="scroll-on-hover pl-4" style="overflow-x: hidden">
      <div class="flex flex-col py-4 text-center">
        <p v-if="onlineUsers.length > 0" class="t-text-xs m-0 my-1 p-0">
          {{ $t('sidebar.status_online') }}
        </p>

        <user-indicator
          v-for="user in onlineUsers"
          :key="user.id"
          class="mb-1"
          :user="user"
          :chat-enabled="chatEnabled"
        />

        <p v-if="awayUsers.length > 0" class="t-text-xs m-0 my-1 p-0">
          {{ $t('sidebar.status_away') }}
        </p>

        <user-indicator
          v-for="user in awayUsers"
          :key="user.id"
          class="mb-1"
          :user="user"
          :chat-enabled="chatEnabled"
        />

        <p v-if="offlineUsers.length > 0" class="t-text-xs m-0 my-1 p-0">
          {{ $t('sidebar.status_offline') }}
        </p>

        <user-indicator
          v-for="user in offlineUsers"
          :key="user.id"
          class="mb-1"
          :user="user"
          :chat-enabled="chatEnabled"
        />
      </div>
    </div>
  </div>
</template>
