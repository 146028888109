import { PERMISSION } from '@/Configs/Constants';

export default [
  {
    path: '/admin/flows',
    component: require('../../components/FlowBuilder/Index').default,
    meta: { permissionRequired: PERMISSION.SETTINGS__FLOWBOTS__MANAGE },
    children: [
      {
        path: '/admin/flows/create',
        component: require('../../components/FlowBuilder/Edit').default,
        props: { action: 'create' },
        meta: { permissionRequired: PERMISSION.SETTINGS__FLOWBOTS__MANAGE },
      },
      {
        path: '/admin/flows/:id',
        component: require('../../components/FlowBuilder/Edit').default,
        props: { action: 'edit' },
        meta: { permissionRequired: PERMISSION.SETTINGS__FLOWBOTS__MANAGE },
      },
      {
        path: '',
        component: require('../../components/AdminIndexBox').default,
        props: { boxName: 'flows' },
        meta: { permissionRequired: PERMISSION.SETTINGS__FLOWBOTS__MANAGE },
      },
    ],
  },
];
