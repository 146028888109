<script lang="ts" setup>
import { WalletPlusLinear } from '@trengo/trengo-icons';
import { ref } from 'vue';

import { postOneTimePurchaseTopUpCommitment } from '@/api';
import { getCarrierCostOptions } from '@/components/Subscription/UsageBasedSubscription/utils';
import { useQueryParameterOpenState } from '@/composables/useQueryParameterOpenState';
import { flashError } from '@/util/errorHelper';

const emit = defineEmits(['success']);
const isSubmitting = ref(false);

const { isOpen, handleClose } = useQueryParameterOpenState('addCarrierCostsModal');

const carrierCostOptions = getCarrierCostOptions()?.filter((option) => option.value !== 0);
const carrierCosts = ref(carrierCostOptions[0].value);

const handleSave = async () => {
  isSubmitting.value = true;
  try {
    await postOneTimePurchaseTopUpCommitment({
      carrier_cost_blocks: carrierCosts.value,
    });
    handleClose();
    emit('success');
    carrierCosts.value = 0;
  } catch (error) {
    flashError('Something went wrong.');
    console.error(error);
  } finally {
    isSubmitting.value = false;
  }
};
</script>

<template>
  <t-modal
    :model-value="isOpen"
    :title="$t('usage_overview.add_carrier_costs_modal_title')"
    variant="default"
    @close="handleClose"
  >
    <div class="h-[400px]">
      <p class="t-text-md text-grey-700" v-html="$t('usage_overview.add_carrier_costs_modal_description')" />
      <form action="POST" @action="handleSave">
        <div>
          <div class="flex flex-col gap-3">
            <div>
              <div class="flex w-full items-center gap-3 rounded-xl bg-grey-200 p-3 pt-2 text-grey-800">
                <t-thumbnail class="mt-1 bg-white">
                  <wallet-plus-linear size="1rem" />
                </t-thumbnail>
                <div class="flex-grow">
                  <div class="t-text-sm flex items-center gap-2 font-semibold">
                    {{ $t(`usage_overview.add_carrier_costs_modal_input_title`) }}
                  </div>
                </div>
                <div class="w-48">
                  <t-select-input
                    :default-value="carrierCosts"
                    :options="carrierCostOptions"
                    class="-mt-1 w-48"
                    @change="carrierCosts = $event"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <template #footer>
      <div class="flex w-full justify-end gap-2">
        <t-button btn-style="secondary" :disabled="isSubmitting" @click="handleClose">
          {{ $t('general.cancel') }}
        </t-button>
        <t-button class="flex items-center" btn-style="primary" :class="{ loader: isSubmitting }" @click="handleSave">
          {{ $t('usage_overview.add_carrier_costs_modal_add_balance') }}
        </t-button>
      </div>
    </template>
  </t-modal>
</template>
