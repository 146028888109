import { PRICING_MODEL } from '@/Configs/Constants';
import { useCompanyProfileStore } from '@/store/pinia';

import type { NavigationGuardWithThis } from 'vue-router';

export const checkPricingModel: NavigationGuardWithThis<undefined> = async () => {
  const companyProfileStore = useCompanyProfileStore();
  await companyProfileStore.init();
  return companyProfileStore?.companyProfile?.pricing_model ?? PRICING_MODEL.USAGE_BASED;
};
