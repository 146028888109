<template>
  <div v-if="integration" class="flex flex-col">
    <div class="mx-auto flex w-2/3 flex-row pt-5">
      <t-inline-banner v-if="showUpgradeBar" type="upgrade">
        <template #icon>
          <arrow-top-circle-linear size="1rem" />
        </template>
        <div class="space-between flex items-center">
          <div class="grow">{{ $t(bannerText, { plan: planName }) }}</div>
          <t-button btn-style="secondary" size="sm" @click="$router.push(SETTINGS_URL.ADMIN__SUBSCRIPTION_SETTINGS)">
            {{ $t('email_settings.upgrade_plan') }}
            <arrow-right-linear size="1.125rem" class="ml-1 inline" />
          </t-button>
        </div>
      </t-inline-banner>
    </div>
    <div v-if="page === 'description'" class="mx-auto flex w-2/3 flex-row py-5" data-test="integration-details">
      <div class="w-2/3">
        <detail-card class="mr-4" :integration="integration" />
      </div>

      <div class="w-1/3">
        <metadata-card :metadata="integration.metadata" />
      </div>
    </div>

    <div v-else class="mx-auto flex w-2/3 flex-row py-5" data-test="integration-installed-details">
      <installed-table class="mt-8 w-full" :integration="integration" />
    </div>

    <router-view />
  </div>
</template>

<script lang="ts">
import { ArrowTopCircleLinear, ArrowRightLinear } from '@trengo/trengo-icons';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import DetailCard from '@/components/IntegrationHub/Components/DetailCard.vue';
import InstalledTable from '@/components/IntegrationHub/Components/InstalledTable.vue';
import MetadataCard from '@/components/IntegrationHub/Components/MetadataCard.vue';
import { FEATURE } from '@/Configs/Constants/Feature';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';
import { adminRoles } from '@/Configs/Constants/UserRoles';
import { checkPricingModel } from '@/middleware/checkPricingModel';
import { useEntitlementsStore } from '@/store/pinia';

import type { DetailPage } from '@/components/IntegrationHub/types';

export default defineComponent({
  name: 'IntegrationHubFilterItem',

  components: {
    InstalledTable,
    DetailCard,
    MetadataCard,
    ArrowTopCircleLinear,
    ArrowRightLinear,
  },

  computed: {
    ...mapStores(useEntitlementsStore),
    ...mapGetters({
      integration: 'integrations/getItem',
    }),

    page(): DetailPage {
      return this.$route.query.page || 'description';
    },

    bannerText() {
      if (adminRoles.includes(this.$root.user.role.name)) {
        return 'integration_hub.upgrade_admin_banner';
      }
      return 'integration_hub.upgrade_supervisor_banner';
    },
  },

  data() {
    return {
      FEATURE,
      SETTINGS_URL,
      planName: '',
      showUpgradeBar: false,
    };
  },

  async beforeUnmount() {
    await this.$store.dispatch('integrations/setIntegrationItem', null);
  },

  async mounted() {
    try {
      await this.$store.dispatch('integrations/fetchIntegrations');
      await this.$store.dispatch('integrations/setIntegrationItem', parseInt(this.$route.params.integrationId));
      await this.$store.dispatch('integrations/fetchIntegration', parseInt(this.$route.params.integrationId));

      const pricingModel = await checkPricingModel();
      if (pricingModel === 'seats') {
        this.planName = 'Enterprise';
      } else if (pricingModel === 'usage') {
        this.planName = this.integration.is_premium ? 'Pro' : 'Boost';
      }
      if (this.integration.is_premium) {
        this.showUpgradeBar = !this.entitlementsStore?.isEntitledToEnforce(
          FEATURE.CUSTOMER_SERVICE__INBOX__INTEGRATIONS__PREMIUM
        );
      } else {
        this.showUpgradeBar = !this.entitlementsStore?.isEntitledToEnforce(
          FEATURE.CUSTOMER_SERVICE__INBOX__INTEGRATIONS__BASIC
        );
      }
    } catch (e) {
      console.error(e);
    }
  },
});
</script>
