<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { FEEDBACK_TYPE } from '@/api/constants';
import { postInboxSuggestedReplyFeedback } from '@/api/modules/suggestedReply';

import ThumbsFeedback from './ThumbsFeedback.vue';

const { t } = useI18n();

const props = defineProps<{
  ticketId: number;
}>();

const isPositiveFeedback = ref<boolean | null>(null);
const status = ref<'ERROR' | 'PENDING' | 'INITIAL' | 'SUCCESS'>('INITIAL');

const text = computed(() => {
  if (status.value === 'ERROR') return t('general.error_something_went_wrong');

  return isPositiveFeedback.value === null
    ? t('tickets.suggest_reply_feedback_prompt')
    : t('tickets.suggest_reply_feedback_result');
});

const handleFeedback = async (isPositive: boolean) => {
  isPositiveFeedback.value = isPositive;
  status.value = 'PENDING';

  const payload = {
    ticket_id: props.ticketId,
    approved: isPositive,
    type: FEEDBACK_TYPE.SUGGEST_REPLIES,
  };

  try {
    await postInboxSuggestedReplyFeedback(payload);
    status.value = 'SUCCESS';
  } catch (error) {
    status.value = 'ERROR';
  } finally {
    status.value = 'SUCCESS';
  }
};

const isDisabled = computed(() => status.value === 'SUCCESS' || status.value === 'PENDING' || status.value === 'ERROR');
</script>

<template>
  <span class="flex items-center" data-test="feature-feedback">
    <ThumbsFeedback
      :is-disabled="isDisabled"
      :is-pressed="isPositiveFeedback === true"
      :tooltip-text="t('tickets.response_good')"
      :is-positive="true"
      @feedback="handleFeedback"
    />
    <ThumbsFeedback
      :is-disabled="isDisabled"
      :is-pressed="isPositiveFeedback === false"
      :tooltip-text="t('tickets.response_bad')"
      :is-positive="false"
      @feedback="handleFeedback"
    />
    <Transition name="transition" mode="out-in">
      <span :key="text.length">{{ text }}</span>
    </Transition>
  </span>
</template>

<style src="./animation.css" scoped />
