import { useEntitlementsStore } from '@/store/pinia';

import type { NavigationGuardWithThis } from 'vue-router';

export const loadEntitlements: NavigationGuardWithThis<undefined> = async (to) => {
  if (to.meta?.allowUnauthenticated) {
    return true;
  }
  const entitlements = useEntitlementsStore();
  await entitlements.init();
};
