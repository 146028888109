import { PERMISSION } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';

export default [
  {
    path: '/admin/company_profile/settings',
    redirect: SETTINGS_URL.ADMIN__ACCOUNT_SETTINGS,
  },
  {
    path: SETTINGS_URL.ADMIN__ACCOUNT_SETTINGS,
    component: require(/* webpackChunkName: "AccountSettings" */ '../../components/AccountSettings/Index').default,
    meta: { permissionRequired: PERMISSION.SETTINGS__COMPANY_PROFILE__MANAGE },
  },
];
