import { FEATURE_FLAG_INBOX } from '@/Configs/Constants';
import { SETTINGS_URL } from '@/Configs/Constants/navigation/settingsUrl';
import { useFeatureFlagStore } from '@/store/pinia';

import helpcenter from './admin/helpcenter';
import unauthorized from './admin/unauthorized';
import wabBroadcasting from './admin/wab_broadcasting';
import ai from './ai';
import integrationHub from './integrationHub';
import reporting from './reporting';
import workflows from './workflows';

export default [
  {
    path: '',
    redirect: '/tickets',
  },
  {
    path: 'tickets',
    component: require('../components/Tickets').default,
    children: [
      {
        path: '',
        component: require('../components/LandingPageIndex.vue').default,
        name: 'tickets-index',
      },
      {
        path: 'show/:id',
        redirect: (to) => {
          return to.path.replace('/show', '');
        },
      },
      {
        path: ':ticketId',
        component: require('../components/Ticket').default,
        name: 'tickets-show',
      },
    ],
  },
  // TODO Alex: Have a chat with anchal about testing on boarding
  {
    path: '/onboard-channels',
    component: () =>
      import(/* webpackChunkName: "OnboardingChannelLandingPage" */ '../components/OnboardingChannelLandingPage'),
  },
  {
    path: '/welcome',
    component: require('../components/OnboardingSteps').default,
  },
  {
    path: '/contacts',
    component: require('../components/Contacts/Contacts').default,
    children: [
      {
        path: '/contacts',
        redirect: (to) => {
          return to.path + '/all';
        },
      },
      {
        path: '/contacts/all',
        component: require('../components/Contacts/ContactsIndex').default,
        props: { scope: 'public' },
      },
      {
        path: '/contacts/mine',
        component: require('../components/Contacts/ContactsIndex').default,
        props: { scope: 'private' },
      },
      { path: '/contacts/:id', component: require('../components/Contacts/Contact').default, name: 'contacts-show' },
      { path: '/sms/import', component: require('../components/Sms/Import').default },
    ],
  },
  {
    path: '/profiles',
    component: require('../components/Profiles/Profiles').default,
    children: [
      { path: '/profiles', component: require('../components/Profiles/ProfilesIndex').default },
      {
        path: '/profiles/show/:id',
        redirect: (to) => {
          return to.path.replace('/show', '');
        },
      },
      { path: '/profiles/:id', component: require('../components/Profiles/Profile').default, name: 'profiles-show' },
    ],
  },
  {
    path: '/contact_groups',
    component: require('../components/ContactGroupsDashboard/ContactGroupsOverview/Index').default,
    name: 'contact-groups-overview',
  },
  {
    path: '/contact_groups/:id',
    component: require('../components/ContactGroupsDashboard/ContactGroupsDetail/Index').default,
    name: 'contact-groups-detail',
  },
  {
    path: '/statistics',
    redirect: (to) => {
      return to.path.replace('statistics', 'reports');
    },
  },
  {
    path: '/statistics/:id',
    redirect: (to) => {
      return to.path.replace('statistics', 'reports');
    },
  },
  {
    path: '/sms',
    component: require('../components/Sms/Index').default,
    children: [
      { path: '/sms/send', component: require('../components/Sms/QuickSend').default },
      { path: '/sms/send-bulk', component: require('../components/Sms/BulkSms').default },
      { path: '/sms/wallet', component: require('../components/Sms/Wallet').default },
      { path: '/sms/balance', redirect: SETTINGS_URL.ADMIN__BALANCE_SETTINGS },
    ],
  },
  {
    path: '/profile',
    redirect: '/profile/overview',
  },
  {
    path: '/profile',
    component: require('../components/Pages/Profile').default,
    children: [
      { path: '/profile/overview', component: require('../components/Pages/Sections/UpdateProfile').default },
      { path: '/profile/password', component: require('../components/Pages/Sections/UpdatePassword').default },
      { path: '/profile/2fa', component: require('../components/Pages/Sections/TwoFactorAuth').default },
      { path: '/profile/preferences', component: require('../components/Pages/Sections/Preferences').default },
      { path: '/profile/get-started', component: require('../components/Onboarding/OnboardingSteps').default },
    ],
  },
  {
    path: '/search',
    component: require('../components/Pages/Search').default,
  },
  {
    path: '/admin/help_center',
    redirect: '/help_center',
  },
  {
    path: '/admin/help_center/:catchAll(.*)',
    redirect: (to) => {
      return `/help_center/${to.params.catchAll}`;
    },
  },
  ...helpcenter,
  ...wabBroadcasting,
  ...integrationHub,
  ...unauthorized,
  ...reporting,
  ...ai,
  ...workflows,
];
