import { MIDDLEWARE_ERROR } from '@/Configs/Constants/middlewareError';
import { useAuthStore } from '@/store/pinia';

import type { NavigationGuardWithThis } from 'vue-router';

export const checkAuthentication: NavigationGuardWithThis<undefined> = async (to) => {
  if (to.meta.allowUnauthenticated) {
    return true;
  }
  const auth = useAuthStore();
  await auth.init();
  if (auth.isAuthenticated || to.name === 'login') {
    return true;
  } else {
    throw new Error(MIDDLEWARE_ERROR.AUTHENTICATION_REQUIRED);
  }
};
