// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

import * as Sentry from '@sentry/vue';

import eventBus from '@/eventBus';
import { vueInstance, app } from '@/main';
import router from '@/routes/routes';

import type { VuexStore } from '@/store';
import type { ComponentPublicInstance } from 'vue';

type ComponentInstance = {
  $store: Store<VuexStore>;
} & ComponentPublicInstance;

let vm: ComponentInstance = null;
export default class {
  /**
   * @return void
   */
  static iniDomElements() {
    $('.select-chosen').chosen({ width: '100%' });
    $('.note-popover').css({ display: 'none' }); //bs4 fix
    $('.input-colorselector').colorselector();
    $('.input-colorpicker').colorpicker({ format: 'hex' });
    $('.input-colorpicker-rgba').colorpicker({ format: 'rgba' });
    $('.input-timepicker').timepicker({ minuteStep: 1, showSeconds: true, showMeridian: true });
    $('.input-datetimepicker2').datetimepicker({
      format: 'Y-m-d H:i',
    });

    $('.input-timepicker24').timepicker({
      defaultTime: false,
      minuteStep: 15,
      showSeconds: false,
      showMeridian: false,
    });
    $('[data-toggle="tooltip"]').tooltip();

    $('[data-visible-if]').each(function () {
      const self = $(this);
      const selector = self.data('visible-if').split('==');
      $(selector[0])
        .change(function () {
          if ($(this).val() == selector[1]) {
            self.show();
          } else {
            self.hide();
          }
        })
        .change();
    });
  }

  static inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  static initAdminApp() {
    // Force to be always framed
    if (!this.inIframe()) {
      window.parent.location.href = '/admin' + window.location.pathname.replace('/settings', '');
      return;
    }

    // Follow the url on the admin
    if (window.location.pathname.startsWith('/settings')) {
      const url = '/admin' + window.location.pathname.replace('/settings', '');
      if (window.parent.location.pathname != url) {
        window.parent.history.replaceState({}, '', url);
      }
    }
  }

  static initFocusDetect() {
    window.hasWindowFocus = true;
    window.lastWindowFocus = new Date().getTime();
    $(window)
      .focus(function () {
        window.hasWindowFocus = true;
        window.lastWindowFocus = new Date().getTime();
      })
      .blur(function () {
        window.hasWindowFocus = false;
      });
  }

  static initResizeHandler() {
    let timer = null;
    $(window).on('resize', () => {
      clearTimeout(timer);
      timer = setTimeout(this.parseModalBreakpoints, 100);
    });
  }

  static parseModalBreakpoints() {
    if (window.is_mobile_device()) {
      return;
    }

    $('.aside:not("#profile2")').attr('style', '');

    const $w = $(window).width(),
      $xl = 1600,
      $lg = 1200,
      $md = 991,
      $sm = 768;
    if ($w > $xl) {
      $('.aside-xl, .aside-xl-right').modal('hide');
    }
    if ($w > $lg) {
      $('.aside-lg').modal('hide');
    }
    if ($w > $md) {
      $('#aside').modal('hide');
      $('.aside-md, .aside-md-right, .aside-sm').modal('hide');
    }
    if ($w > $sm) {
      $('.aside-sm').modal('hide');
    }
  }

  static handleMaintenanceStatus() {
    PusherInstance.subscribe('app-update').bind('update', () => {
      $('#Updater').show();
    });
  }

  /**
   * @return void
   */
  static initialize() {
    $(() => {
      // redirect web. to app., except for Ionic app (inIframe)
      if (!this.inIframe() && window.location.hostname === 'web.trengo.eu') {
        window.location.href = window.location.href.replace('://web.trengo.eu', '://app.trengo.eu');
      }

      this.initFocusDetect();

      if (typeof GUEST_LAYOUT != 'undefined') {
        return;
      }

      this.iniDomElements();

      if (ADMIN_LAYOUT) {
        this.initAdminApp();
      } else {
        this.initVue();
      }
    });
  }

  /**
   * @return void
   */
  static initVue() {
    let auth = {};
    let globalSocketId = null;
    const authenticateBatch = async () => {
      const currentAuth = auth;
      auth = {};

      if (!Object.keys(currentAuth).length) {
        return;
      }

      const res = await axios
        .post('/pusher/auth', {
          socket_id: globalSocketId,
          channel_name: Object.keys(currentAuth),
        })
        .catch((e) => console.error('initVue pusher authorize', e));

      res?.data?.forEach?.((channel) => {
        if (typeof currentAuth[channel.channel_name] !== 'function') {
          return;
        }

        try {
          currentAuth[channel.channel_name](null, channel.auth_key);
        } catch (e) {
          console.error('InitVue call channel', e);
        }
      });
    };
    window.PusherInstance = new Pusher(PUSHER_KEY, {
      authorizer: (channel) => {
        return {
          authorize: function (socketId, callback) {
            globalSocketId = socketId;
            // Instant callback if the channel authentication token is already known
            if (window.privatePusherChannels && window.privatePusherChannels[channel.name]) {
              callback(null, { auth: window.privatePusherChannels[channel.name].auth });
              delete window.privatePusherChannels[channel.name];
              return;
            }

            auth[channel.name] = callback;
            _.throttle(authenticateBatch, 3000, { leading: false })();
          },
        };
      },
      encrypted: true,
      cluster: 'eu',
      forceTLS: true,
    });

    if (window.APP_ENV !== 'development') {
      Sentry.init({
        app,
        dsn: `${window.SENTRY_DSN}`,
        integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
        environment: window.APP_ENV,
        tracesSampleRate: 0.008,
        replaysSessionSampleRate: 0.001,
        replaysOnErrorSampleRate: 0.01,

        //release: 'the-release-name',// TODO see: https://docs.sentry.io/workflow/releases/?platform=javascript
      });
    }

    window.eventBus = eventBus;

    window.router = router;

    $('.vue-component').click(function (e) {
      router.push($(this).attr('href'));
      $('.navbar-nav .nav-item').removeClass('active');
      $(this).parent().addClass('active');
      e.preventDefault();
    });

    // If clicked on a ticket show url, open inline via VUE router
    $('body').on('click', 'a.linkified', function () {
      if ($(this).attr('href').indexOf('/tickets') !== -1) {
        router.push('/tickets/' + $(this).attr('href').split('/tickets/')[1]);
        return false;
      }
    });

    this.handleMaintenanceStatus();
    this.initResizeHandler();

    vm = vueInstance;
  }
}

export { vm };
